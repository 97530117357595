import { productSchema } from '@/api/schemes';
import service from '../service';

export async function getProducts(params: productSchema.GetProductParams) {
  return service.get<never, productSchema.ProductList>(`/admin/products`, {
    params,
  });
}

export async function addProduct(params: FormData) {
  return service.post<never, productSchema.ProductScheme>(
    `/admin/products`,
    params,
  );
}

export async function updateProduct({
  id,
  data,
}: productSchema.UpdateProductParams) {
  return service.put<never, productSchema.ProductScheme>(
    `/admin/products/${id}`,
    data,
  );
}

export async function getDepartments() {
  return service.get<never, productSchema.ProductDepartmentList>(
    '/departments/list',
  );
}

export async function addDepartment(
  params: productSchema.ProductDeparmentScheme,
) {
  return service.post<never, productSchema.ProductDeparmentScheme>(
    `/departments/`,
    params,
  );
}

export async function updateDepartment({
  id,
  ...params
}: productSchema.ProductDeparmentScheme) {
  return service.put<never, productSchema.ProductDeparmentScheme>(
    `/departments/${id}`,
    params,
  );
}

export async function removeDepartment(departmentId: number) {
  return service.delete(`/departments/${departmentId}`);
}

export async function getProductUnits() {
  return service.get<never, productSchema.ProductUnitList>('/units/list');
}

export async function getProductOptions() {
  return service.get<never, productSchema.ProductOptionList>(
    '/product-options',
  );
}

export async function addProductOption(
  params: productSchema.AddProductOptionParams,
) {
  return service.post<never, productSchema.ProductOptionScheme>(
    `/product-options`,
    params,
  );
}

export async function updateProductOption({
  id,
  ...params
}: productSchema.UpdateProductOptionParams) {
  return service.put<never, productSchema.ProductOptionScheme>(
    `/product-options/${id}`,
    params,
  );
}

export async function removeProductOption(productOptionId: number) {
  return service.delete(`/product-options/${productOptionId}`);
}

export async function getProductMaximumAmount({
  company_unit_id,
  unit_id,
}: productSchema.GetProductMaximumAmountParams) {
  return service.get<never, productSchema.ProductMaximumAmountDetail>(
    `/admin/products/maximum_amount/${company_unit_id}/${unit_id}`,
  );
}

export async function updateProductMaximumAmount({
  unit_id,
  maximum_amount,
}: productSchema.UpdateProductMaximumAmountParams) {
  return service.put<never, productSchema.ProductList>(
    `/admin/products/maximum_amount/${unit_id}`,
    { maximum_amount },
  );
}
