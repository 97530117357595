import { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Typography,
  Button,
  Box,
  MenuItem,
  Grid,
  TextField,
} from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Helmet } from 'react-helmet-async';

import { productQueries } from '@/hooks/queries';
import Input from '@/components/Input';
import { useStores } from '@/stores/index';

interface ModalAddProductForm {
  unit_id: number;
  maximum_amount: number;
}

const productMaximumAmountSchema = yup.object().shape({
  unit_id: yup
    .number()
    .required('É necessário selecionar uma unidade de venda'),
  maximum_amount: yup
    .number()
    .required('Quantidade obrigatória')
    .transform(value => (Number.isNaN(value) ? null : value))
    .nullable(),
});

function ProductMaximumAmount() {
  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const { control, handleSubmit, setValue } = useForm<ModalAddProductForm>({
    resolver: yupResolver(productMaximumAmountSchema),
    defaultValues: {
      maximum_amount: 0,
    },
  });

  const [unitId, setUnitId] = useState<number>();

  const { mutate: updateProductMaximumAmount } =
    productQueries.useUpdateProductMaximumAmount();

  const { data: unitList } = productQueries.useGetProductUnitList();
  const { data: productMaximumAmount } =
    productQueries.useGetProductMaximumAmount({
      company_unit_id: Number(selectedCompany?.id),
      unit_id: unitId,
    });

  const onSubmit: SubmitHandler<ModalAddProductForm> = data => {
    const { maximum_amount, unit_id } = data;

    updateProductMaximumAmount({
      maximum_amount,
      unit_id,
    });
  };

  useEffect(() => {
    if (productMaximumAmount?.data?.maximum_amount) {
      setValue('maximum_amount', productMaximumAmount.data.maximum_amount);
    }
  }, [setValue, productMaximumAmount]);

  return (
    <>
      <Helmet>
        <title>Parâmetros de Produto</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Parâmetros de Produto
          </Typography>
        </Stack>

        <Grid container spacing={2} rowSpacing={2}>
          <Grid item md={6}>
            <Card>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                style={{ padding: 20 }}>
                <Grid container spacing={2} rowSpacing={2}>
                  <Grid item md={12}>
                    <Stack spacing={2}>
                      <Controller
                        name="unit_id"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            select
                            label="Unidade de venda"
                            error={!!error?.message}
                            helperText={error?.message}
                            {...field}
                            onChange={e => {
                              field.onChange(e.target.value);
                              setUnitId(Number(e.target.value));
                            }}>
                            {unitList?.data.map(unit => (
                              <MenuItem key={unit.id} value={unit.id}>
                                {unit.unit} - {unit.description}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />

                      <Input
                        label="Quantidade máxima de compra"
                        control={control}
                        name="maximum_amount"
                        type="number"
                      />
                    </Stack>
                  </Grid>
                </Grid>

                <Stack
                  direction="row"
                  display="flex"
                  justifyContent="flex-end"
                  spacing={{
                    xs: 0.5,
                    sm: 1,
                  }}
                  sx={{
                    marginTop: 2,
                  }}>
                  <Button size="large" type="submit" variant="contained">
                    Salvar
                  </Button>
                </Stack>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ProductMaximumAmount;
