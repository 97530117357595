import { useRoutes, Navigate } from 'react-router-dom';
import Layout from '@/components/layout';
import Orders from '@/pages/orders';
import Login from '@/pages/login';
import LoginApp from '@/pages/login/LoginApp';
import Products from '@/pages/products';
import Users from '@/pages/users';
import Config from '@/pages/config';
import Delivery from '@/pages/delivery';
import Stores from '@/pages/stores';
import PaymentMethods from '@/pages/paymentMethods';
import OrderProducts from '@/pages/reports/orderProducts';
import OrderProductsSold from '@/pages/reports/orderProductsSold';
import ReportOrders from '@/pages/reports/orders';
import Customers from '@/pages/customers';
import Notifications from '@/pages/notifications';
import Banners from '@/pages/banners';
import Plans from '@/pages/plans';
import Terms from '@/pages/terms';
import OrdersRating from '@/pages/reports/ordersRating';
import OrdersConfig from '@/pages/ordersConfig';
import PreRegister from '@/pages/preRegister';
import Sellers from '@/pages/sellers';
// import Queue from '@/pages/queue';
import Blacklist from '@/pages/reports/blacklist';
import ProductMaximumAmount from '@/pages/productMaximumAmount';

function Router() {
  const tenant_slug = localStorage.getItem('@mercapp:tenant_slug');
  const token = localStorage.getItem(`@${tenant_slug}:token`);

  return useRoutes([
    {
      path: '/',
      element: !!token ? <Layout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to="/orders" /> },
        { path: 'orders', element: <Orders /> },
        { path: 'products', element: <Products /> },
        { path: 'users', element: <Users /> },
        { path: 'customers', element: <Customers /> },
        { path: 'delivery', element: <Delivery /> },
        { path: 'reports/excluded-products', element: <OrderProducts /> },
        { path: 'reports/reverse-orders', element: <ReportOrders /> },
        { path: 'reports/products-sold', element: <OrderProductsSold /> },
        { path: 'reports/orders-rating', element: <OrdersRating /> },
        { path: 'notifications', element: <Notifications /> },
        { path: 'banners', element: <Banners /> },
        { path: 'plans', element: <Plans /> },
        { path: 'terms', element: <Terms /> },
        { path: 'config/company', element: <Config /> },
        { path: 'config/lojas', element: <Stores /> },
        { path: 'config/payment-methods', element: <PaymentMethods /> },
        { path: 'config/orders', element: <OrdersConfig /> },
        {
          path: 'config/products-maximum-amount',
          element: <ProductMaximumAmount />,
        },
        { path: 'sellers', element: <Sellers /> },
        // { path: 'queue', element: <Queue /> },
        { path: 'reports/blacklist', element: <Blacklist /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'login-app',
      element: <LoginApp />,
    },
    {
      path: 'pre-register',
      element: <PreRegister />,
    },
    {
      path: '*',
      element: <h2>Página não encontrada</h2>,
    },
  ]);
}

export default Router;
