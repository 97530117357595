import { useState, SyntheticEvent, useEffect } from 'react';
import { Card, Tabs, Tab, Box, Typography, Button } from '@mui/material';
import { Container } from '@mui/system';
import { format } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';
import { toast } from 'react-toastify';

import { useStores } from '@/stores/index';

import OrderDetailItem from '@/components/orderDetailItem';
import { companyUnitQueries, orderQueries } from '@/hooks/queries';
import OrderProductItem from '@/components/orderProductItem';

import formatCurrency from '@/utils/formatCurrency';
import {
  useCaptureOrderPayment,
  useUpdateOrder,
  useCancelOrderPaymentPartial,
} from '@/hooks/queries/order';

import Scrollbar from '@/components/scrollbar';
import Iconify from '@/components/iconyfy';
import ModalOrderProduct from '@/components/modals/modalOrderProduct';
import ModalCancelOrder from '@/components/modals/modalCancelOrder';
import ModalFulfilmentOrder from '@/components/modals/modalFulfilmentOrder';
import ModalPrintOrder from '@/components/modals/modalPrintOrder';
import ModalPrintOrderDossier from '@/components/modals/modalPrintOrderDossier';
import ModalAddShopper from '@/components/modals/modalAddShopper';
import ModalReturnOrderStatus from '@/components/modals/modalReturnOrderStatus';
import ModalBeebee from '@/components/modals/modalBeebee';
import Overlay from '@/components/overlay';

import { orderSchema } from '@/api/schemes';

import {
  LineInfo,
  OrderProducts,
  ActionsContainer,
  OrderWarning,
  WithoutPermission,
} from './styles';

import formatCPF from '@/utils/formatCPF';
import formatPhone from '@/utils/formatPhone';
import ModalOrderTaxes from '@/components/modals/modalOrderTaxes';

interface OrderDetailProps {
  orderId: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface OrderActionProps {
  statusId: number;
  disabled: boolean;
  handleChangeOrderStatus: () => void;
  disableEmployeeOrderApproval: () => boolean;
}

interface ReplaceProduct {
  [key: string]: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ mt: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `order-tab-${index}`,
    'aria-controls': `order-tabpanel-${index}`,
  };
}

function OrderAction({
  statusId,
  disabled,
  handleChangeOrderStatus,
  disableEmployeeOrderApproval,
}: OrderActionProps) {
  switch (statusId) {
    case 1:
    case 2:
    case 3:
      return (
        <Button
          disabled={disableEmployeeOrderApproval()}
          onClick={handleChangeOrderStatus}
          variant="contained"
          size="large"
          startIcon={<Iconify icon="mdi:cart-outline" />}>
          Aprovar Pedido
        </Button>
      );
    case 4:
      return (
        <Button
          onClick={handleChangeOrderStatus}
          variant="contained"
          size="large"
          startIcon={<Iconify icon="mdi:moped" />}>
          Informar Pedido Enviado
        </Button>
      );
    case 13:
      return (
        <Button
          onClick={handleChangeOrderStatus}
          disabled={disabled}
          variant="contained"
          size="large"
          startIcon={<Iconify icon="mdi:cart-outline" />}>
          Finalizar Separação
        </Button>
      );

    case 7:
    case 8:
      return (
        <Button
          disabled
          variant="contained"
          size="large"
          startIcon={<Iconify icon="mdi:check-circle" />}>
          Pedido finalizado
        </Button>
      );
    default:
      return (
        <Button
          onClick={handleChangeOrderStatus}
          variant="contained"
          size="large"
          startIcon={<Iconify icon="mdi:check-circle" />}>
          Marcar como Entregue
        </Button>
      );
  }
}

function OrderDetail({ orderId }: OrderDetailProps) {
  const { companyStore, userStore } = useStores();
  const { selectedCompany, tenantSlug } = companyStore;
  const { user } = userStore;

  const { mutate: updateOrder } = useUpdateOrder();
  const { mutate: captureOrderPayment } = useCaptureOrderPayment();
  const { mutate: cancelOrderPaymentPartial } = useCancelOrderPaymentPartial();

  const {
    data: orderData,
    status,
    isFetching,
  } = orderQueries.useGetUniqueOrder({
    id: orderId,
  });

  const { data: orderDossierData } = orderQueries.useGetOrderDossier({
    id: orderId,
    status_id: orderData?.data.status.id,
  });

  const { data: paymentMethodsData } =
    companyUnitQueries.useGetCompanyUnitPaymentMethodList({
      company_unit_id: Number(selectedCompany?.id),
    });

  const [tab, setTab] = useState(0);
  const [statusId, setStatusId] = useState(0);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openAddShopperModal, setOpenAddShopperModal] = useState(false);
  const [openPrintOrderModal, setOpenPrintOrderModal] = useState(false);
  const [openPrintOrderDossierModal, setOpenPrintOrderDossierModal] =
    useState(false);
  const [openCancelOrderModal, setOpenCancelOrderModal] = useState(false);
  const [openFulfilmentModal, setOpenFulfilmentModal] = useState(false);
  const [openBeebeeModal, setOpenBeebeeModal] = useState(false);
  const [openOrderTaxesModal, setOpenOrderTaxesModal] = useState(false);
  const [openReturnOrderStatusModal, setOpenReturnOrderStatusModal] =
    useState(false);
  const [surplusValue, setSurplusValue] = useState(0);
  const [orderProducts, setOrderProducts] = useState<
    orderSchema.OrderProduct[]
  >([]);

  const notUpdateStatuses = [4, 5, 6, 7, 8, 13];

  const selectedPaymentMethod = paymentMethodsData?.data.find(
    payment =>
      payment.company_unit_payment_method_id ===
      orderData?.data.payment_method_id,
  );

  const handleProductModal = () => {
    setOpenProductModal(!openProductModal);
  };

  const handleAddShopperModal = () => {
    setOpenAddShopperModal(!openAddShopperModal);
  };

  const handleCancelOrderModal = () => {
    setOpenCancelOrderModal(!openCancelOrderModal);
  };

  const handleFulfimentModal = () => {
    setOpenFulfilmentModal(!openFulfilmentModal);
  };

  const handleOrderTaxesModal = () => {
    setOpenOrderTaxesModal(!openOrderTaxesModal);
  };

  const handleReturnOrderStatusModal = () => {
    setOpenReturnOrderStatusModal(!openReturnOrderStatusModal);
  };

  const handleBeebeeModal = () => {
    setOpenBeebeeModal(!openBeebeeModal);
  };

  const handleFinishFulfimentOrder = () => {
    try {
      if (selectedPaymentMethod?.name?.toLowerCase() === 'pix') {
        const amount =
          orderData?.data &&
          orderData?.data.original_total_value - orderData?.data.totalValue;

        if (amount && amount > 0) {
          cancelOrderPaymentPartial({ order_id: orderId, amount });
        }
      } else {
        captureOrderPayment({ order_id: orderId });
      }

      if (orderData?.data.deliver === 'deliver') {
        updateOrder({
          id: orderId,
          status_id: 4,
        });
        setStatusId(4);
      } else {
        updateOrder({
          id: orderId,
          status_id: 5,
        });
        setStatusId(5);
      }
    } catch {
      toast.error('Ocorreu um erro no fechamento do pedido', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleOpenPrintOrderModal = () => {
    setOpenPrintOrderModal(!openPrintOrderModal);

    if (!openPrintOrderModal && !notUpdateStatuses.includes(statusId)) {
      updateOrder({
        id: orderId,
        status_id: 13,
      });
      setStatusId(13);
    }
  };

  const handleOpenPrintOrderDossierModal = () => {
    setOpenPrintOrderDossierModal(!openPrintOrderDossierModal);
  };

  const changeTab = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleChangeOrderStatus = () => {
    if (status === 'success') {
      switch (statusId) {
        case 1:
        case 2:
        case 3:
          updateOrder({
            id: orderId,
            status_id: 13,
          });
          setStatusId(13);
          break;

        case 4:
          updateOrder({
            id: orderId,
            status_id: 6,
          });
          setStatusId(6);
          break;

        case 13:
          handleFulfimentModal();
          break;

        default:
          updateOrder({
            id: orderId,
            status_id: 7,
          });
          setStatusId(7);
      }
    }
  };

  useEffect(() => {
    if (orderData?.data.status.id) {
      setStatusId(orderData?.data.status.id);
    }
  }, [orderData?.data.status.id]);

  useEffect(() => {
    if (status === 'success') {
      if (orderData.data.finalValue > orderData.data.original_total_value) {
        setSurplusValue(
          orderData.data.finalValue - orderData.data.original_total_value,
        );
      } else {
        setSurplusValue(0);
      }
    }
  }, [orderData]);

  useEffect(() => {
    if (orderData?.data?.products && orderData?.data?.products?.length > 0) {
      setOrderProducts(
        orderData.data.products.filter(
          product => product.status.description !== 'Cancelado',
        ),
      );
    } else {
      setOrderProducts([]);
    }
  }, [orderData?.data.products]);

  const replaceProduct: ReplaceProduct = {
    replace: 'Trocar',
    remove: 'Remover',
    contact: 'Entrar em contato',
  };

  const replaceProductOptions = ['replace', 'remove', 'contact'];

  const disableReturnStatuses = [1, 2, 3, 7, 8];

  const disableEmployeeOrderApproval = () => {
    const newOrderStatus = [1, 2, 3];
    const isEmployee = user?.role === 'employee' || user?.role === 'employee2';
    const newOrder = newOrderStatus.includes(statusId);
    const orderInPersonPayment = selectedPaymentMethod?.type === 'in-person';

    const hasMaximumApprovalLimit =
      selectedCompany?.maximum_employee_order_approval &&
      orderData?.data.totalValue &&
      isEmployee &&
      newOrder;

    const isInPersonPayment = orderInPersonPayment && isEmployee && newOrder;
    const isInAwaitPayment = isEmployee && statusId === 2;

    if (isInPersonPayment) {
      return true;
    }

    if (isInAwaitPayment) {
      return true;
    }

    if (hasMaximumApprovalLimit) {
      return (
        selectedCompany.maximum_employee_order_approval <
        orderData.data.totalValue
      );
    }

    return false;
  };

  if (!user) {
    return null;
  }

  const getPixStatus = (statusPix: string) => {
    if (statusPix === 'awaiting-payment') {
      return 'Aguardando pagamento';
    } else if (statusPix === 'confirmed') {
      return 'Confirmado';
    } else {
      return 'Aguardando envio';
    }
  };

  const OrderRating = () => {
    const starArray = Array.from(
      { length: orderData?.data.rating || 0 },
      (_, index) => index + 1,
    );

    if (orderData?.data.status.id === 7) {
      if (orderData?.data.rating) {
        return (
          <span>
            {starArray.map((_, index) => (
              <span key={index}>⭐</span>
            ))}
          </span>
        );
      }
    }
    return <span>Não avaliado</span>;
  };

  return (
    <Overlay loading={isFetching}>
      <Card>
        <Container>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tab}
                onChange={changeTab}
                aria-label="order tabs"
                variant="fullWidth">
                <Tab label="Pedido" {...a11yProps(0)} />
                <Tab label="Detalhes" {...a11yProps(1)} />
                <Tab label="Entrega" {...a11yProps(2)} />
                {user.role === 'administrator' && (
                  <Tab label="Pagamento" {...a11yProps(3)} />
                )}
              </Tabs>
            </Box>
            {!disableEmployeeOrderApproval() ? (
              <>
                <TabPanel value={tab} index={0}>
                  <LineInfo>
                    <Typography variant="subtitle1" color="#999" fontSize={16}>
                      Valor original do pedido
                    </Typography>

                    <Typography variant="subtitle1" fontSize={18}>
                      {formatCurrency(
                        orderData?.data.original_total_value || 0,
                      )}
                    </Typography>
                  </LineInfo>
                  {!!orderData?.data.totalNotIncludedValue &&
                    orderData?.data.totalNotIncludedValue > 0 && (
                      <LineInfo>
                        <Typography
                          variant="subtitle1"
                          color="#999"
                          fontSize={12}>
                          Produtos cancelados
                        </Typography>

                        {orderData?.data.totalNotIncludedValue && (
                          <Typography
                            variant="subtitle1"
                            fontSize={14}
                            color="#f00">
                            -
                            {formatCurrency(
                              orderData?.data.totalNotIncludedValue,
                            )}
                          </Typography>
                        )}
                      </LineInfo>
                    )}
                  {!!orderData?.data.totalIncludedValue &&
                    orderData?.data.totalIncludedValue > 0 && (
                      <LineInfo>
                        <Typography
                          variant="subtitle1"
                          color="#999"
                          fontSize={12}>
                          Produtos adicionados
                        </Typography>
                        {orderData?.data.totalIncludedValue && (
                          <Typography
                            variant="subtitle1"
                            fontSize={14}
                            color="#0ae063">
                            +
                            {formatCurrency(orderData?.data.totalIncludedValue)}
                          </Typography>
                        )}
                      </LineInfo>
                    )}
                  {!!orderData?.data.totalModifiedProductsValue && (
                    <LineInfo>
                      <Typography
                        variant="subtitle1"
                        color="#999"
                        fontSize={12}>
                        Produtos modificados
                      </Typography>
                      {orderData?.data.totalModifiedProductsValue && (
                        <Typography variant="subtitle1" fontSize={14}>
                          {formatCurrency(
                            orderData?.data.totalModifiedProductsValue,
                          )}
                        </Typography>
                      )}
                    </LineInfo>
                  )}
                  <LineInfo>
                    <Typography variant="subtitle1" color="#999" fontSize={12}>
                      Total de produtos
                    </Typography>

                    <Typography variant="subtitle1" fontSize={14}>
                      {formatCurrency(orderData?.data.totalValue || 0)}
                    </Typography>
                  </LineInfo>
                  <LineInfo>
                    <Typography variant="subtitle1" color="#999" fontSize={12}>
                      Taxa de serviço
                    </Typography>

                    <Typography variant="subtitle1" fontSize={14}>
                      {formatCurrency(orderData?.data.service_tax_value || 0)}
                    </Typography>
                  </LineInfo>
                  <LineInfo>
                    <Typography variant="subtitle1" color="#999" fontSize={12}>
                      Taxa de entrega
                    </Typography>

                    <Typography variant="subtitle1" fontSize={14}>
                      {formatCurrency(orderData?.data.deliver_value || 0)}
                    </Typography>
                  </LineInfo>
                  <LineInfo>
                    <Typography variant="subtitle1" color="#999" fontSize={12}>
                      Gorjeta
                    </Typography>

                    <Typography variant="subtitle1" fontSize={14}>
                      {formatCurrency(orderData?.data.tip || 0)}
                    </Typography>
                  </LineInfo>
                  <LineInfo>
                    <Typography variant="subtitle1" color="#999" fontSize={16}>
                      Valor final
                    </Typography>

                    <Typography variant="subtitle1" fontSize={18}>
                      {orderData?.data.finalValue.toString() &&
                        formatCurrency(orderData.data.finalValue)}
                    </Typography>
                  </LineInfo>

                  {!!surplusValue &&
                    selectedPaymentMethod?.type === 'online' && (
                      <OrderWarning>
                        <Typography variant="subtitle1" fontSize={12}>
                          Atenção: o valor total do pedido não pode ultrapassar
                          o valor pré aprovado.
                        </Typography>
                        <Typography variant="subtitle1" fontSize={12}>
                          Reduza a quantidade de itens até alcançar o valor
                          autorizado.
                        </Typography>
                        <Typography variant="subtitle1" fontSize={12}>
                          Valor excedente: {formatCurrency(surplusValue)}
                        </Typography>
                      </OrderWarning>
                    )}

                  <OrderProducts>
                    <Scrollbar>
                      {orderData?.data?.products.map(product => (
                        <OrderProductItem
                          key={product.id}
                          product={product}
                          orderCreatedAt={orderData.data.created_at}
                        />
                      ))}
                    </Scrollbar>
                  </OrderProducts>

                  <ActionsContainer>
                    <Button
                      disabled={
                        user.role === 'employee' ||
                        statusId === 7 ||
                        statusId === 8
                      }
                      onClick={handleCancelOrderModal}
                      variant="text"
                      size="large"
                      color="error"
                      startIcon={<Iconify icon="mdi:close" />}>
                      Cancelar Pedido
                    </Button>
                    <Button
                      // disabled={statusId !== 13}
                      onClick={handleProductModal}
                      variant="contained"
                      size="large"
                      startIcon={<Iconify icon="mdi:edit-outline" />}>
                      Editar Pedido
                    </Button>
                  </ActionsContainer>

                  <ActionsContainer>
                    <Button
                      disabled={disableEmployeeOrderApproval()}
                      onClick={handleOpenPrintOrderModal}
                      variant="contained"
                      size="large"
                      startIcon={<Iconify icon="mdi:printer-outline" />}>
                      Imprimir Pedido
                    </Button>

                    <OrderAction
                      statusId={statusId}
                      disabled={
                        !!surplusValue &&
                        selectedPaymentMethod?.type === 'online'
                      }
                      handleChangeOrderStatus={handleChangeOrderStatus}
                      disableEmployeeOrderApproval={
                        disableEmployeeOrderApproval
                      }
                    />
                  </ActionsContainer>
                  {user.role === 'administrator' && (
                    <ActionsContainer>
                      {/* <Button
                        disabled={statusId > 3 && statusId < 13}
                        onClick={handleAddShopperModal}
                        variant="contained"
                        size="large"
                        startIcon={<Iconify icon="mdi:user-outline" />}>
                        {orderData?.data.shopper_id
                          ? 'Alterar responsável'
                          : 'Adicionar responsável'}
                      </Button> */}
                      <Button
                        disabled={statusId !== 7 && statusId !== 8}
                        onClick={handleOpenPrintOrderDossierModal}
                        variant="contained"
                        size="large"
                        startIcon={<Iconify icon="mdi:printer-outline" />}>
                        Gerar Dossiê
                      </Button>

                      <Button
                        disabled={user.role !== 'administrator'}
                        onClick={handleOrderTaxesModal}
                        variant="contained"
                        size="large"
                        startIcon={<Iconify icon="mdi:attach-money" />}>
                        Alterar Taxas
                      </Button>
                    </ActionsContainer>
                  )}
                  <ActionsContainer>
                    <Button
                      disabled={
                        user.role !== 'administrator' ||
                        disableReturnStatuses.includes(statusId)
                      }
                      onClick={handleReturnOrderStatusModal}
                      variant="contained"
                      size="large"
                      startIcon={<Iconify icon="mdi:arrow-back" />}>
                      Voltar Status
                    </Button>
                    <Button
                      disabled={statusId !== 4}
                      onClick={handleBeebeeModal}
                      variant="contained"
                      size="large"
                      startIcon={
                        <Iconify icon="mdi:delivery-dining-outline" />
                      }>
                      Solicitar Frete
                    </Button>
                  </ActionsContainer>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  {status === 'success' && (
                    <>
                      <OrderDetailItem
                        label="Responsável pelo pedido"
                        value={orderData.data?.shopper?.name}
                      />
                      <OrderDetailItem label="Pedido" value={orderId} />
                      <OrderDetailItem
                        label="Horário do pedido"
                        value={format(
                          new Date(orderData.data.created_at as string),
                          'dd/MM/yyyy HH:mm',
                        )}
                      />
                      {orderData.data.status.id === 8 && (
                        <OrderDetailItem
                          label="Motivo do cancelamento"
                          value={orderData.data.cancel_reason_message}
                        />
                      )}
                      {orderData.data.datetime && (
                        <OrderDetailItem
                          label="Dia e horário de entrega"
                          value={formatToTimeZone(
                            new Date(orderData.data.deliver_date as string),
                            `DD/MM/YYYY ${orderData.data.datetime.starts_at} - ${orderData.data.datetime.ends_at} `,
                            { timeZone: 'UTC' },
                          )}
                        />
                      )}
                      <OrderDetailItem
                        label="Cliente"
                        value={`${
                          orderData.data.customer.user.nickname
                        } | ${formatPhone(orderData.data.customer.phone)}`}
                      />
                      <OrderDetailItem
                        label="CPF"
                        value={`${formatCPF(orderData.data.customer.cpf)}`}
                      />

                      {tenantSlug === 'mercapp' && (
                        <OrderDetailItem
                          label="Assinante"
                          value={
                            orderData.data.subscriber === 'yes' ? 'Sim' : 'Não'
                          }
                        />
                      )}

                      <OrderDetailItem
                        label="Quantidade de compras"
                        value={
                          orderData.data.totalOrders ||
                          'Primeira compra na loja'
                        }
                      />
                      <OrderDetailItem
                        label="Método de entrega"
                        value={
                          orderData.data.deliver === 'deliver'
                            ? 'Entrega'
                            : 'Retirada na loja'
                        }
                      />
                      <OrderDetailItem
                        label="Em caso de falta de produto"
                        value={
                          replaceProductOptions.includes(
                            orderData.data.replace_product,
                          )
                            ? replaceProduct[orderData.data.replace_product]
                            : orderData.data.replace_product
                        }
                      />
                      <OrderDetailItem
                        label="Quantidade de produtos"
                        value={orderData.data.totalProducts}
                      />
                      <OrderDetailItem
                        label="Quantidade de itens"
                        value={orderData.data.totalItens}
                      />

                      <OrderDetailItem
                        label="Valor economizado"
                        value={formatCurrency(orderData.data.total_discount)}
                      />

                      <OrderDetailItem
                        label="Versão do app"
                        value={orderData.data.app_version}
                      />

                      <OrderDetailItem
                        label="Sistema operacional"
                        value={orderData.data.so}
                      />

                      {orderData.data.rating && (
                        <OrderDetailItem
                          label="Avaliação"
                          value={<OrderRating />}
                        />
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  {status === 'success' && (
                    <>
                      <OrderDetailItem label="Motorista" value="" />
                      <OrderDetailItem label="Telefone" value="" />
                      <OrderDetailItem label="Placa" value="" />
                    </>
                  )}
                </TabPanel>
                <TabPanel value={tab} index={3}>
                  {status === 'success' && (
                    <>
                      <OrderDetailItem
                        label="Método de pagamento"
                        value={`${selectedPaymentMethod?.name} - ${
                          selectedPaymentMethod?.type === 'in-person'
                            ? 'Pagamento na Entrega/Retirada'
                            : 'Pagamento online'
                        }`}
                      />

                      {selectedPaymentMethod?.type === 'online' && (
                        <>
                          {selectedPaymentMethod?.name?.toLowerCase() ===
                          'pix' ? (
                            <OrderDetailItem
                              label="Status do pagamento"
                              value={getPixStatus(orderData.data.status_pix)}
                            />
                          ) : (
                            <>
                              <OrderDetailItem
                                label="Titular do cartão"
                                value={
                                  orderData.data.order_payment_card
                                    ?.card_holder_name
                                }
                              />
                              <OrderDetailItem
                                label="CPF"
                                value={
                                  orderData.data.order_payment_card
                                    ?.card_holder_cpf &&
                                  `${formatCPF(
                                    orderData.data.order_payment_card
                                      ?.card_holder_cpf,
                                  )}`
                                }
                              />
                              <OrderDetailItem
                                label="Últimos 4 dígitos"
                                value={
                                  orderData.data.order_payment_card?.last_digits
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </TabPanel>
              </>
            ) : (
              <WithoutPermission>
                Pedido sem permissão de aprovação. <br /> Solicite aprovação ao
                administrador.
              </WithoutPermission>
            )}
          </Box>

          <ModalOrderProduct
            modalIsOpen={openProductModal}
            closeModal={handleProductModal}
            orderId={orderId}
            orderProducts={orderProducts}
          />

          <ModalPrintOrder
            modalIsOpen={openPrintOrderModal}
            closeModal={handleOpenPrintOrderModal}
            selectedPaymentMethod={selectedPaymentMethod}
            order={orderData?.data}
          />

          <ModalPrintOrderDossier
            modalIsOpen={openPrintOrderDossierModal}
            closeModal={handleOpenPrintOrderDossierModal}
            order={orderDossierData?.data}
          />

          <ModalCancelOrder
            modalIsOpen={openCancelOrderModal}
            closeModal={handleCancelOrderModal}
            orderId={orderId}
          />

          <ModalFulfilmentOrder
            modalIsOpen={openFulfilmentModal}
            closeModal={handleFulfimentModal}
            orderId={orderId}
            handleAddShopperModal={handleAddShopperModal}
          />

          <ModalAddShopper
            modalIsOpen={openAddShopperModal}
            closeModal={handleAddShopperModal}
            order={orderData?.data}
            onFinishFulfimentOrder={handleFinishFulfimentOrder}
          />

          <ModalOrderTaxes
            modalIsOpen={openOrderTaxesModal}
            closeModal={handleOrderTaxesModal}
            order={orderData?.data}
          />

          <ModalReturnOrderStatus
            modalIsOpen={openReturnOrderStatusModal}
            closeModal={handleReturnOrderStatusModal}
            order={orderData?.data}
          />

          <ModalBeebee
            modalIsOpen={openBeebeeModal}
            closeModal={handleBeebeeModal}
            orderId={orderId}
          />
        </Container>
      </Card>
    </Overlay>
  );
}

export default OrderDetail;
