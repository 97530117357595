import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { productRequests } from '@/api/requests';
import { productSchema } from '@/api/schemes';

export function useGetProductList(params?: productSchema.GetProductParams) {
  async function requestFn() {
    return productRequests.getProducts(params!);
  }

  const query = useQuery<productSchema.ProductList>(
    ['getProducts', params],
    requestFn,
  );

  return query;
}

export function useGetProductDepartmentList() {
  async function requestFn() {
    return productRequests.getDepartments();
  }

  const query = useQuery<productSchema.ProductDepartmentList>(
    ['getDepartments'],
    requestFn,
  );

  return query;
}

export function useGetProductUnitList() {
  async function requestFn() {
    return productRequests.getProductUnits();
  }

  const query = useQuery<productSchema.ProductUnitList>(
    ['getUnits'],
    requestFn,
  );

  return query;
}

export function useGetProductOptionList() {
  async function requestFn() {
    return productRequests.getProductOptions();
  }

  const query = useQuery<productSchema.ProductOptionList>(
    ['getProductOptions'],
    requestFn,
  );

  return query;
}

export function useAddProduct() {
  const queryClient = useQueryClient();

  async function mutationFn(params: FormData) {
    return productRequests.addProduct(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getProducts');
      toast.success('Produto adicionado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição do Produto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateProduct() {
  const queryClient = useQueryClient();
  async function mutationFn(params: productSchema.UpdateProductParams) {
    return productRequests.updateProduct(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getProducts');
      toast.success('Produto atualizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização do Produto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useAddProductOption() {
  const queryClient = useQueryClient();

  async function mutationFn(params: productSchema.AddProductOptionParams) {
    return productRequests.addProductOption(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getProductOptions');
      toast.success('Opção de produto adicionada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição da opção do produto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateProductOption() {
  const queryClient = useQueryClient();
  async function mutationFn(params: productSchema.UpdateProductOptionParams) {
    return productRequests.updateProductOption(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getProductOptions');
      toast.success('Opção de produto atualizada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização da opção do produto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveProductOption() {
  const queryClient = useQueryClient();

  async function mutationFn(id: number) {
    return productRequests.removeProductOption(id!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getProductOptions');
      toast.success('Opção de produto removida com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção da opção do produto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useGetProductMaximumAmount(
  params: productSchema.GetProductMaximumAmountParams,
) {
  async function requestFn() {
    return productRequests.getProductMaximumAmount(params!);
  }

  const query = useQuery<productSchema.ProductMaximumAmountDetail>(
    ['getProductMaximumAmount', params],
    requestFn,
    {
      enabled: Boolean(params?.unit_id),
    },
  );

  return query;
}

export function useUpdateProductMaximumAmount() {
  async function mutationFn(
    params: productSchema.UpdateProductMaximumAmountParams,
  ) {
    return productRequests.updateProductMaximumAmount(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      toast.success('Parâmetros atualizados com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização dos parâmetros', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
